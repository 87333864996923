import styled from 'styled-components';
import React, { useState, useEffect } from 'react';
import { questionTypeQuestion } from 'constants/questionTypes';
import { words } from '../translations';

// Example data structure for your 5 Likert options
// Index or value can be [1..5], or [0..4], whichever you prefer.
const LIKERT_CHOICES = [
  { value: 2, label: 'Strongly Disagree', emoji: '1' },
  { value: 4, label: 'Disagree', emoji: '2' },
  { value: 6, label: 'Neutral', emoji: '3' },
  { value: 8, label: 'Agree', emoji: '4' },
  { value: 10, label: 'Strongly Agree', emoji: '5' },
];

const LikertScale = ({
  id,
  question,
  saveUpdatedAnswers,
  currentQuestionDet,
  language,
}) => {
  const [selectedValue, setSelectedValue] = useState(null);

  useEffect(() => {
    // If there's a saved response, set it.
    if (currentQuestionDet?.response) {
      setSelectedValue(currentQuestionDet.response.response);
    } else {
      // Or default to null or a specific value
      setSelectedValue(null);
    }
  }, [currentQuestionDet]);

  const handleChoiceClick = (choiceValue) => {
    setSelectedValue(choiceValue);
    saveValue(choiceValue);
  };

  const saveValue = (value) => {
    SaveResponse(value);
  };

  function SaveResponse(value) {
    const QuesResp = {
      id: question?.dimension,
      factor: question?.factor,
      q: question?.q,
      ques_order: question?.order,
      response: value,
      role: question?.role,
      reverse: question?.reverse || false,
      clicked: true,
      ques_sort_order: currentQuestionDet?.id,
    };

    saveUpdatedAnswers(currentQuestionDet.id, QuesResp, questionTypeQuestion);
  }

  return (
    <Container>

      <ChoicesWrapper>
        {LIKERT_CHOICES.map((choice) => {
          const isSelected = choice.value === selectedValue;
          return (
            <ChoiceButton
              key={choice.value}
              isSelected={isSelected}
              onClick={() => handleChoiceClick(choice.value)}
            >
              <EmojiSpan>{choice.emoji}</EmojiSpan>
              <LabelSpan>{words[choice.label][language]}</LabelSpan>
            </ChoiceButton>
          );
        })}
      </ChoicesWrapper>
    </Container>
  );
};

export default LikertScale;

/* -- STYLED COMPONENTS -- */

const Container = styled.div`
  width: 100%;
  padding: 20px 0;
  user-select: none;
`;

const QuestionText = styled.div`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: left;
`;

const ChoicesWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ChoiceButton = styled.button`
  flex: 1;
  margin: 0 5px;
  background-color: ${(props) => (props.isSelected ? '#D5E2F9' : '#fff')};
  border: 2px solid
    ${(props) => (props.isSelected ? '#2D70E2' : 'transparent')};
  border-radius: 8px;
  padding: 12px;
  cursor: pointer;
  text-align: center;
  transition: all 0.3s ease;
  outline: none;
  height: 100px;

  &:hover {
    background-color: #D5E2F9;
  }

  @media (max-width: 500px) {
    padding: 8px 4px;
    min-width: 18%;
  }

`;

const EmojiSpan = styled.span`
  font-size: 24px;
  display: block;
`;

const LabelSpan = styled.span`
  font-size: 12px;
  display: block;
  margin-top: 4px;


  @media (max-width: 500px) {
    font-size: 9px;
    text-align: center;
  }

`;

const ScaleLabelsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
`;

const ScaleLabel = styled.span`
  font-size: 14px;

  @media (max-width: 500px) {
    font-size: ${(props) => (props.language === 'EN' ? '14px' : '12px')};
  }
`;
