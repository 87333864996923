import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import format from "date-fns/format";
import { words } from '../translations';
import Hockey_v2 from "./CustomCompleted/Hockey_v2"
import Hockey_v3 from "./CustomCompleted/Hockey_v3"


const FR1 = "Nous ne pouvons pas obtenir d'informations sur cette enquête"
const FR2 = "Cette enquête a été complétée"
const FR3 = "Cette enquête a été fermée et n'est plus accessible"
const FR4 = "Hmm! Il semble que cette enquête a expiré"

const ES1 = "No podemos obtener información sobre esta encuesta"
const ES2 = "Esta encuesta ha sido completada"
const ES3 = "Esta encuesta ha sido cerrada y ya no es accesible"
const ES4 = "Hmm! Parece que esta encuesta ha expirado"

const TUR1 = "Bu anket hakkında bilgi alamıyoruz"
const TUR2 = "Bu anket tamamlandı"
const TUR3 = "Bu anket kapatıldı ve artık erişilebilir değil"
const TUR4 = "Hmm! Bu anketin süresinin dolduğu görünüyor"


const EN5 = "As part of the OSIC Sport Environment Assessment for Hockey Canada and Hockey in Canada, the SEA team is conducting 60-minute semi-structured interviews with hockey constituents, stakeholders, and youth from [insert date range]. This provides an opportunity for deeper context, diversity of views, and unique experiences to shape a culture of well-being in hockey."
const EN6 = "The interviews are completely confidential, and are independent of Hockey Canada, Member Branches, and minor hockey associations meaning that they will never have access to any individual responses. "
const EN7 = "If you are interested in continuing the conversation further, please click the unique link below to enter your contact information:"


const FR5 = "Dans le cadre de l'évaluation de l'environnement sportif de l'OSIC pour Hockey Canada et le hockey au Canada, l'équipe de l'ESA mène des entretiens semi-structurés de 60 minutes avec des parties prenantes du hockey, des intervenants et des jeunes de [insérer la plage de dates]. Cela offre une occasion d'approfondir le contexte, la diversité des points de vue et les expériences uniques pour façonner une culture de bien-être dans le hockey."
const FR6 = "Les entretiens sont entièrement confidentiels et sont indépendants de Hockey Canada, des branches membres et des associations de hockey mineur, ce qui signifie qu'ils n'auront jamais accès à aucune réponse individuelle."
const FR7 = "Si vous souhaitez poursuivre la conversation, veuillez cliquer sur le lien unique ci-dessous pour saisir vos coordonnées de contact:"


const Completed = ({ status, language, statusText, completedOn, surveyStatus,title,whitelabel }) => {
  const [endDate, setEndDate] = useState(false)
  const [org_info, setOrgInfo] = useState()
  const [step, setStep] = useState(0)
  const [customMessage, setCustomMessage] = useState('')



  useEffect(()=>{
    if(whitelabel?.endMessage){
      setCustomMessage(whitelabel.endMessage)
    }
    else{
      setCustomMessage(statusText ? statusText : words["survey_status1"][language])
    }

  },[whitelabel])

  useEffect(() => {
    if (completedOn) {
      setEndDate(format(new Date(completedOn), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"))
    }
  }, [completedOn])

  useEffect(() => {
    if (whitelabel) {
      setOrgInfo(whitelabel)
    }
  }, [whitelabel])

  useEffect(()=>{
    if(language == 'FR'){
      setCustomMessage(FR1)
    }

    if(language == 'TUR'){
      setCustomMessage(TUR1)
    }
  
    if(language == 'ES'){
      setCustomMessage(ES1)
    }
  
    if (language === 'FR' && status === 2) {
      setCustomMessage(FR2)
    }

    if (language == 'TUR' && status === 2) {
      setCustomMessage(TUR2)
    }
  
    if (language== 'FR' && status === 3) {
      setCustomMessage(FR2)
    }
  
    if (language === 'ES' && status === 2) {
      setCustomMessage(ES2)
    }
  
    if (language == 'ES' && status === 3) {
      setCustomMessage('Esta encuesta ha sido completada')
    }

    if (language == 'TUR' && status === 3) {
      setCustomMessage('Bu anket tamamlandı')
    }
  
  
  
    if (status > 4 && status < 11) {
      setCustomMessage('This survey has been closed and is no longer accessible')
      if (language === 'FR') {
        setCustomMessage(FR3)
      }
      if (language === 'ES') {
        setCustomMessage(ES3)
      }
      if (language === 'TUR') {
        setCustomMessage(TUR3)
      }
    }
    if (status === 100) {
      setCustomMessage('Hmm! It looks like this survey has expired')
      if (language === 'FR') {
        setCustomMessage(FR4)
      }
      if (language === 'ES') {
        setCustomMessage(ES4)
      }
      if (language === 'TUR') {
        setCustomMessage(TUR4)
      }
    }
  },[language])

  


  const getTitle = () =>{
    if(language === 'FR'){
      return 'Indice de Culture'
    }
    if(language == 'ES'){
      return 'Índice de Cultura'
    }
    return title? title:'Culture Index'
  }



  const getCustomMessage = (org_data) => {
   


    if (org_data?.public_intro == "hockey"){

      if(step ==2){
        return (
          <div style={{marginBottom:150}}>
             <QuestionText>
          {language == 'EN'
            ? 'Thank You for Your Participation!'
            : 'Merci'}
        </QuestionText>

        <Description>
          {language == 'EN'
            ? "Your responses have been successfully submitted. We appreciate your time and input."
            : 'Vous avez terminé le sondage avec succès.'}
        </Description>

        <Description>
          <ul>
            <li>{language == 'EN'
            ? 'Your responses will be analyzed along with others to provide a comprehensive view of hockey in Canada and Hockey Canada’s organizational culture;'
            : 'Vos réponses seront analysées avec d\'autres pour fournir une vue d\'ensemble du hockey au Canada et de la culture organisationnelle de Hockey Canada ;'}</li>

            <li>{language == 'EN'
            ? 'OSIC will publish overall results in Spring 2025.'
            : 'OSIC publiera les résultats globaux au printemps 2025.'}</li>


          </ul>
        </Description>

        <QT>
          {language == 'EN' ? 'Interview Opportunity' : 'Opportunité d\'entrevue'}
        </QT>


        <Description>
          {language == 'EN' ?
          "If you'd like to participate in a follow-up interview, please click the button below. This will take you to a separate, secure sign-up page that is not connected to your survey responses in any way:"
          : "Si vous souhaitez participer à une entrevue de suivi, veuillez cliquer sur le bouton ci-dessous. Cela vous mènera à une page d'inscription distincte et sécurisée qui n'est en aucun cas liée à vos réponses au sondage :"}
        </Description>


          <a href={language == 'EN' ?
          "https://questionnaire.simplesurvey.com/f/s.aspx?s=4c2f8318-e6a1-48bb-93a8-541a1987146a&ds=xhCf8iv82B":
          "https://questionnaire.simplesurvey.com/f/s.aspx?s=4c2f8318-e6a1-48bb-93a8-541a1987146a&lang=FR&ds=xhCf8iv82B"} target="_blank">
            <Button>
            {language=='EN'? 'Sign Up for Post-Survey Interview':'Inscrivez-vous pour une entrevue post-sondage'}
              </Button>
          </a>

          </div>
        )
      }



      return (
        <>
        <QuestionText>
          {language == 'EN'
            ? 'Almost done! One more question!'
            : 'Presque terminé ! Une question de plus !'}
        </QuestionText>

        <Description>
          {language == 'EN'
            ? "As mentioned, we're conducting interviews to inform the Hockey Sport Environment Assessment."
            : "Comme mentionné, nous menons des entretiens pour informer l'évaluation de l'environnement sportif du hockey."}
        </Description>

        <Description>
        {language == 'EN'
            ? 'Here are key points about the interviews:'
            : 'Voici les points clés sur les entretiens :'}
        </Description>

          <Description>
            <ul>
              <li>{language == 'EN'
            ? 'Participation in the interview is entirely voluntary;'
            : 'La participation à l\'entretien est entièrement volontaire ;'}</li>
              
                <li>{language == 'EN'
            ? 'The interview sign-up is completely separate from the survey you completed; and,'
            : 'L\'inscription à l\'entretien est complètement distincte du sondage que vous avez complété ; et,'}</li>

                <li>{language == 'EN'
            ? 'Your survey responses remain anonymous, regardless of whether you choose to participate in an interview.'
            : 'Vos réponses au sondage restent anonymes, que vous choisissiez ou non de participer à un entretien.'}</li>

            </ul>
          </Description>

        <Description>
          {language == 'EN'
            ? 'Would you be interested in participating in a phone interview in the future?'
            : 'Seriez-vous intéressé à participer à un entretien téléphonique à l\'avenir ?'}
        </Description>

        <Description>

       
        <Button onClick={()=>setStep(2)}>
          {language=='EN'?'Yes':'Oui'}
          </Button>

          <Button onClick={()=>setStep(1)}>
          {language=='EN'?'No':'Non'}
          </Button>

        </Description>

        <Description>
          {language == 'EN'
            ? 'You will find a link to a separate and secure interview registration form on the next page. Please click it if you are interested in being interviewed.'
            : 'Vous trouverez un lien vers un formulaire d\'inscription à l\'entretien séparé et sécurisé sur la page suivante. Veuillez cliquer dessus si vous êtes intéressé par un entretien.'}
        </Description>

        <Description style={{marginTop:30}}>
          {language =='EN'
            ? <p>
              If you have any questions, please don't hesitate to reach out to the SEA Team:<br/>
              Toll-free: 1-833-974-1281<br/>
              Email: <a href="mailto:info@sea-hockey-ems.ca">info@sea-hockey-ems.ca</a><br/><br/>
              For more information about the Sport Environment Assessment, visit <a href="www.sea-hockey-ems.ca">www.sea-hockey-ems.ca</a><br/><br/>
              <i><strong>Important Notice:</strong> This survey is not intended for filing complaints or reports about maltreatment in sport. If you need to submit such a complaint or report, please visit the Office of the Sport Integrity Commissioner (OSIC) <a href="https://sportintegritycommissioner.ca/complaints-reports">website</a>.</i>
            
            </p>:
            <p style={{whiteSpace:'pre-line'}}>
              Si vous avez des questions, n'hésitez pas à contacter l'équipe de l'ESA <br/>
              Sans frais : 1-833-974-1281<br/>
              Courriel : <a href="mailto:info@sea-hockey-ems.ca">info@sea-hockey-ems.ca</a><br/><br/>
              Pour plus d'informations sur l'évaluation de l'environnement sportif, visitez <a href="www.sea-hockey-ems.ca">www.sea-hockey-ems.ca</a><br/><br/>
              <i><strong>Avis important :</strong> Ce sondage n'est pas destiné à déposer des plaintes ou des rapports sur des mauvais traitements dans le sport. Si vous devez soumettre une telle plainte ou un tel rapport, veuillez visiter le site Web du Commissaire à l'intégrité dans le sport (OSIC) <a href="https://sportintegritycommissioner.ca/complaints-reports">site Web</a>.</i>
            </p>
          }
        </Description>

        </>

      )
    }

    if (org_data?.public_intro == "hockey_members"){
      return <Hockey_v2 language={language}/>

    }

    if(org_data?.public_intro == "hockey_participants"){
      return <Hockey_v3 language={language} />
    }

      return (
        <>
        <Emoji> <span role="img" aria-label="completed">🎉</span> </Emoji>
        <QuestionText>{words["Thank you for completing the"][language]} {getTitle()}</QuestionText>
        <Description>
          {customMessage}
          {endDate ? ` ${format(
            new Date(endDate.toLocaleString()),
            "EEEE, MMM do h:mm a"
          )}.` : '.'}


        </Description>
        </>
      )


    }

  return (
    <FeedbackContainer>
      {getCustomMessage(org_info)}
    </FeedbackContainer>
  )
}


export default Completed;


const ImportantNotice = ({ children }) => (
  <p style={{ margin: '20px 0', fontWeight: 'bold', color: 'red', fontSize: '14px' }}>
    {children}
  </p>
);

const FeedbackContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width:100%;
  max-width:600px;
  font-family: 'Raleway', sans-serif;
  padding-bottom:40px;


  li{
    margin-bottom:10px;
  }

  @media(max-width:768px){
  padding:10px;
  }

  @media(max-height:700px){
    padding-top:40px;
  }
`;

const QT = styled.div`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 1rem;

`

const QuestionText = styled.h2`
  margin-bottom: 1rem;
  color:#2A3039;
  font-size: 20px;
  font-weight: 600;
`;

const Description = styled.div`
color: #2A3039;
font-family: 'Raleway';
font-style: normal;
font-weight: 400;
font-size: 16px;
margin-bottom:10px;
line-height: 1.5;

p{
  white-space: pre-line;
}
`
const Emoji = styled.div`
    font-size: 48px;
    margin-bottom:24px;
`


const Button = styled.button`
  background-color: #2d70e2;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  margin-bottom:10px;
  transition: all 0.3s;
  margin-right:10px;
  margin-top:5px;
  &:hover {
    background-color: #1d9a7d;
  }
`;