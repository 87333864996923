import React, { useState, useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid';

import { useDispatch } from "react-redux";

import { Button, Header, Icon, Modal } from 'semantic-ui-react'

import {
    RESET_VALIDATION_TOKEN,
    UPDATE_BRIEF_RESPONSE_ANSWERS,
    UPDATE_STORED_RESPONSE,
    SG_UPDATE_BRIEF_RESPONSE,
} from "constants/actions";

import {words} from '../AuditV3/translations'

// const VALIDATION_TOKEN = localStorage.getItem("validation_token");
//         storedResponseFound={storedResponseFound}
// storedResponse={storedResponse}
function AuditExistingDataFound({ 
    setQuestionNo,
    setVisualQuestionNo,
    briefResponseId,
    token,
    resetValidationToken,
    storedResponse,
    storedResponseFound,
    setCurrentQuestionNo,
    setCurrentQuestionDet,
    setQuestionSequenceMap, 
    setShowingStoredResponse,
    language}) {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(true)

    const [timed, setTimed] = useState(true)
    const [defaultMessage, setDefaultMessage] = useState({
        header: "Searching ...",
        text: "Looking for an earlier response, please wait ..."
    })
// c0ab26e7-151b-4acd-b73e-9b6ca86f5e83
    function ResetSurvey() {
        const NewValidationToken = uuidv4()
        localStorage.setItem("validation_token", NewValidationToken)
        resetValidationToken(NewValidationToken)
        dispatch({
            type: SG_UPDATE_BRIEF_RESPONSE,
            payload: {
                id: briefResponseId,
                query: `?token=${token}`,
                validation_token: NewValidationToken,
                reset_stored_data : true, // makes validation_token null
            }
        })
        setOpen(false)
        setShowingStoredResponse(true)
    }

    useEffect(() => {
		const timer1 = setTimeout(() => {
			setTimed(false)
		}, 1000);
		return () => clearTimeout(timer1);
	},[timed])


    function Continue() {
        let NUMS = storedResponse?.current_question
        if (NUMS) {    
            const _checkFdbk = storedResponse?.questionSequenceMap?.[NUMS]?.response?.skipped
            // check if follow up and feedback is skipped
            if (_checkFdbk) {
                NUMS--
            }
            setCurrentQuestionNo(NUMS)
            setCurrentQuestionDet(storedResponse?.questionSequenceMap?.[NUMS])
            setQuestionSequenceMap(storedResponse?.questionSequenceMap)
            
        }

        dispatch({
            type: UPDATE_BRIEF_RESPONSE_ANSWERS,
            payload: storedResponse, 
            
        });
        setShowingStoredResponse(true)
        setQuestionNo(NUMS)
        setOpen(false)
        setVisualQuestionNo(storedResponse?.visual_question || NUMS)
    }

    return (
        <Modal
            open={open}
            size='small'
        >
            <Header icon>
                {timed ? 
                    <Icon loading name='spinner' />
                    :
                    <Icon name='archive' />
                }
                {language? words['Searching'][language] :defaultMessage?.header}

            </Header>
            <Modal.Content>
                <p>
                    {language? words['Searching2'][language] : defaultMessage?.text}
                </p>
            </Modal.Content>
            <Modal.Actions>
                <Button color='grey' onClick={ResetSurvey}
                    id={'btn-existingdata-reset'}
                    size="small"
                >
                    <Icon name='remove' /> {language? words['Restart'][language]:'Restart survey'}
                </Button>

                <Button
                    disabled={timed}
                    color='green'  onClick={Continue}
                    size="small"
                    id={'btn-existingdata-resume'}>
                    <Icon name='checkmark' /> {language? words['Load'][language]:'Load saved survey'}
                </Button>

            </Modal.Actions>
        </Modal>
    )
}

export default AuditExistingDataFound