import React from "react";
import { Navigate } from "react-router-dom";

import Health from "health";

import Survey from "survey/TeamCulture/index";

import PersonalitySurvey from "survey/Personality/index";
import TeamIntelligence from "survey/TeamCulture/TeamIntelligence/index";

import EdiSurvey from "survey/EDI";
import EdiPrivacy from "survey/EDI/Privacy";

import OTPV2 from "survey/OTPV2/index";
import OTP from "survey/OTP/index";
import OTPPrivacy from "survey/OTP/Privacy";
import Template from "survey/Template/index";

import Blink from "survey/Blink/index";
// import Debrief from 'survey/Debriefs/index'
// import Demo from "survey/Audit/demo"

import Subfactor from "survey/Test/subfactor_value";
import Tested from "survey/AuditV2/Survey";
import Context from "survey/Context";
// import Legacy from 'survey/Test/legacy'

import CultureAudit from "survey/Audit/index";
// New Survey Design May 2023
import CultureAuditV2 from "survey/AuditV2/index";
import CultureAuditV3 from "survey/AuditV3/index";
import CultureAuditV2a from "survey/AuditV2/SurveyV2";
import Test from "survey/Test";
import InitialReport from "survey/Context/Initial_Report";
// START to check if employee is already in the debrief survey
import Start from "survey/Start";


import Recon from "survey/Recon/index";
import Demo from "survey/Demo/index";

export const routes = [
  {
    path: "/survey/:token",
    element: <Survey />,
  },
  {
    path: "/personality-survey/:token",
    element: <PersonalitySurvey />,
  },
  {
    path: "/team-intelligence/:token",
    element: <TeamIntelligence />,
  },
  {
    path: "/test",
    element: <Tested />,
  },
  {
    path: "/roi-calculator",
    element: <Context />,
  },
  {
    path: "/context",
    element: <Context />,
  },
  {
    path: "/report",
    element: <InitialReport />,
  },
  {
    path: "/culture-audit/start/*",
    element: <Start />,
  },
  {
    path: "/culture-audit/:token",
    element: <CultureAudit />,
  },
  {
    path: "/culture-audit-v2/:token",
    element: <CultureAuditV2 />,
  },
  {
    path: "/culture-audit-v3/:token",
    element: <CultureAuditV3 />,
  },
  {
    path: "/culture-audit-v2a/:token",
    element: <CultureAuditV2a />,
  },
  {
    path: "/edi-survey/:token",
    element: <EdiSurvey />,
  },
  {
    path: "/edi-survey/privacy",
    element: <EdiPrivacy />,
  },
  {
    path: "/otp-v2/:token",
    element: <OTPV2 />,
  },
  {
    path: "/otp/:token",
    element: <OTP />,
  },
  {
    path: "/otp/privacy",
    element: <OTPPrivacy />,
  },
  {
    path: "/insight/:token",
    element: <Template />,
  },
  {
    path: "/recon",
    element: <Recon />,
  },
  {
    path: "/demo",
    element: <Demo />,
  },
  {
    path: "/new",
    children: [
      {
        path: "blink",
        element: <Blink />,
      },
      { path: "test/animate", element: <Test /> },
      { path: "test/sub-factor", element: <Subfactor /> },
      // { path: 'legacy', element: <Legacy /> },
      // {
      //   path: "debrief",
      //   element: <Debrief />,
      // },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
  { path: "/", element: <Survey /> },
  {
    path: "health",
    element: <Health />,
  },
  { path: "*", element: <Navigate to="/404" /> },
];

export default routes;
