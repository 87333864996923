import React, { useState, useEffect } from 'react';
import styled from 'styled-components';




const HockeyCompleted2 = ({language}) =>{

    const [step,setStep] = useState(0)

    if(step ==2 || step==1){
        return (
          <div style={{marginBottom:150}}>
             <QuestionText>
          {language == 'EN'
            ? 'Thank You for Your Participation!'
            : 'Merci'}
        </QuestionText>

        <Description>
          {language == 'EN'
            ? "Your responses have been successfully submitted. We appreciate your time and input."
            : 'Vous avez terminé le sondage avec succès.'}
        </Description>

        <Description>
          <ul>
            <li>{language == 'EN'
            ? 'Your responses will be analyzed along with others to provide a comprehensive view of hockey in Canada;'
            : 'Vos réponses seront analysées avec d\'autres pour fournir une vue d\'ensemble du hockey au Canada;'}</li>

            <li>{language == 'EN'
            ? 'OSIC will publish overall results in Spring 2025.'
            : 'BCIS publiera les résultats globaux au printemps 2025.'}</li>


          </ul>
        </Description>

        <QT>
          {language == 'EN' ? 'Interview Opportunity' : 'Opportunité d\'entrevue'}
        </QT>



        <Description>
          {language == 'EN' ?
          "If you'd like to participate in a follow-up interview, please click the button below. This will take you to a separate, secure sign-up page that is not connected to your survey responses in any way:"
          : "Si vous souhaitez participer à une entrevue de suivi, veuillez cliquer sur le bouton ci-dessous. Cela vous mènera à une page d'inscription distincte et sécurisée qui n'est en aucun cas liée à vos réponses au sondage :"}
        </Description>


          <a href={language == 'EN' ?
          "https://questionnaire.simplesurvey.com/f/s.aspx?s=4c2f8318-e6a1-48bb-93a8-541a1987146a&ds=UT02Ixi94hS":
          "https://questionnaire.simplesurvey.com/f/s.aspx?s=4c2f8318-e6a1-48bb-93a8-541a1987146a&lang=FR&ds=UT02Ixi94h"} target="_blank">
            <Button>
            {language=='EN'? 'Sign Up for Post-Survey Interview':'Inscrivez-vous pour une entrevue post-sondage'}
              </Button>
          </a>

          </div>
        )
      }



      return (
        <>
        <QuestionText>
          {language == 'EN'
            ? 'Almost done! One more question!'
            : 'Presque terminé ! Une question de plus !'}
        </QuestionText>

        <Description>
          {language == 'EN'
            ? "As mentioned, we're conducting interviews to inform the Hockey Sport Environment Assessment."
            : "Comme mentionné, nous menons des entretiens pour informer l'évaluation du milieu sportif du hockey."}
        </Description>

        <Description>
        {language == 'EN'
            ? 'Here are key points about the interviews:'
            : 'Voici les points clés sur les entretiens :'}
        </Description>

          <Description>
            <ul>
              <li>{language == 'EN'
            ? 'Participation in the interview is entirely voluntary;'
            : 'La participation à l\'entretien est entièrement volontaire ;'}</li>
              
                <li>{language == 'EN'
            ? 'The interview sign-up is completely separate from the survey you completed; and,'
            : 'L\'inscription à l\'entretien est complètement distincte du sondage que vous avez complété ; et,'}</li>

                <li>{language == 'EN'
            ? 'Your survey responses remain anonymous, regardless of whether you choose to participate in an interview.'
            : 'Vos réponses au sondage restent anonymes, que vous choisissiez ou non de participer à un entretien.'}</li>

            </ul>
          </Description>

        <Description>
          {language == 'EN'
            ? 'Would you be interested in participating in a phone interview in the future?'
            : 'Seriez-vous intéressé à participer à un entretien téléphonique à l\'avenir ?'}
        </Description>

        <Description>

       
        <Button onClick={()=>setStep(2)}>
          {language=='EN'?'Yes':'Oui'}
          </Button>

          <Button onClick={()=>setStep(1)}>
          {language=='EN'?'No':'Non'}
          </Button>

        </Description>

        <Description>
          {language == 'EN'
            ? 'You will find a link to a separate and secure interview registration form on the next page. Please click it if you are interested in being interviewed.'
            : 'Vous trouverez un lien vers un formulaire d\'inscription à l\'entretien séparé et sécurisé sur la page suivante. Veuillez cliquer dessus si vous êtes intéressé par un entretien.'}
        </Description>

        <Description style={{marginTop:30}}>
          {language =='EN'
            ? <p>
              {/* If you have any questions, please don't hesitate to reach out to the SEA Team:<br/>
              Toll-free: 1-833-974-1281<br/>
              Email: <a href="mailto:info@sea-hockey-ems.ca">info@sea-hockey-ems.ca</a><br/><br/> */}
              For more information about the Sport Environment Assessment, visit <a href="https://www.sea-hockey-ems.ca" target="_blank">www.sea-hockey-ems.ca</a><br/><br/>
              <i><strong>Important Notice:</strong> This survey is not intended for filing complaints or reports about maltreatment in sport. If you need to submit such a complaint or report, please visit the Office of the Sport Integrity Commissioner (OSIC) <a href="https://sportintegritycommissioner.ca/complaints-reports" target="_blank">website</a>.</i>
            
            </p>:
            <p style={{whiteSpace:'pre-line'}}>
              {/* Si vous avez des questions, n'hésitez pas à contacter l'équipe de l'ESA <br/>
              Sans frais : 1-833-974-1281<br/>
              Courriel : <a href="mailto:info@sea-hockey-ems.ca">info@sea-hockey-ems.ca</a><br/><br/> */}
              Pour plus d'informations sur l'évaluation du milieu sportif, visitez <a href="https://www.sea-hockey-ems.ca" target="_blank">www.sea-hockey-ems.ca</a><br/><br/>
              <i><strong>Avis important :</strong> Ce sondage n'est pas destiné à déposer des plaintes ou des rapports sur des mauvais traitements dans le sport. Si vous devez soumettre une telle plainte ou un tel rapport, veuillez visiter le site Web du Commissaire à l'intégrité dans le sport (BCIS) <a href="https://sportintegritycommissioner.ca/complaints-reports" target="_blank">site Web</a>.</i>
            </p>
          }
        </Description>

        
       

     


        </>

      )
    }

export default HockeyCompleted2


const QT = styled.div`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 1rem;

`

const QuestionText = styled.h2`
  margin-bottom: 1rem;
  color:#2A3039;
  font-size: 20px;
  font-weight: 600;
`;

const Description = styled.div`
color: #2A3039;
font-family: 'Raleway';
font-style: normal;
font-weight: 400;
font-size: 16px;
margin-bottom:10px;
line-height: 1.5;
`
const Emoji = styled.div`
    font-size: 48px;
    margin-bottom:24px;
`


const Button = styled.button`
  background-color: #2d70e2;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  margin-bottom:10px;
  transition: all 0.3s;
  margin-right:10px;
  margin-top:5px;
  &:hover {
    background-color: #1d9a7d;
  }
`;