import { commentTypeQuestion } from "constants/questionTypes";
import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { words } from "../translations";

const Comments = ({ question, saveUpdatedAnswers, currentQuestionDet, language }) => {
  // console.log("question", question)

  const textareaRef = useRef();
  const [feedbackText, setFeedbackText] = useState('');

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight - 16}px`;
    }
  }, []);

  const handleInput = (e) => {
    e.target.style.height = 'inherit';
    e.target.style.height = `${e.target.scrollHeight - 16}px`;
  };

  const handleChange = (event) => {
    setFeedbackText(event.target.value);
    const ResponseObj = {
      ques_sort_order: currentQuestionDet?.id,
      survey_q_id: currentQuestionDet.id,
      feedback: event.target.value,
      question_type: currentQuestionDet?.question_type,
      clicked: true,
    }

    saveUpdatedAnswers(currentQuestionDet?.id, ResponseObj, commentTypeQuestion)
  };

  useEffect(() => {
    if (currentQuestionDet?.response) {
      setFeedbackText(currentQuestionDet?.response?.feedback)
    } else {
      setFeedbackText('')
    }
  }, [currentQuestionDet])

  const getLanguageQuestion = () =>{
    if(language === 'FR'){
      return question?.fr
    }
    if(language === 'TUR'){
      return question?.tr
    }


      return question?.question
    
  }


  return (
    <FeedbackContainer>
      <Title>{words['Feedback'][language]}</Title>
      <Description>
      {getLanguageQuestion()}
      </Description>

      <StyledTextarea
        id={`txt_${currentQuestionDet?.id}`}
        value={feedbackText}
        onChange={handleChange}
        placeholder={words["Type your answer here"][language]}
        ref={textareaRef}
        onInput={handleInput} />
    </FeedbackContainer>

  )

}

export default Comments



const FeedbackContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width:600px;
  width:100%;
  font-family: 'Raleway', sans-serif;
`;

const Description = styled.div`
color: #2A3039;
font-family: 'Raleway';
font-style: normal;
font-weight: 400;
font-size: 16px;
margin-bottom:34px;
line-height:1.6;
`
const Title = styled.div`
color: #2D70E2;
text-transform: uppercase;
font-size:14px;
font-weight:600;
margin-bottom:40px;
`

const StyledTextarea = styled.textarea`
  display: block;
  width: 100%; 
  padding: 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #2A3039;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  resize: none;
  font-family: 'Raleway', sans-serif;

  &:focus {
    color: #495057;
    background-color: #fff;
    border-color: #2D70E2;
    outline: 0;
  }
`;