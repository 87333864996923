import styled from 'styled-components';
import React, { useState, useEffect } from 'react';

// Import your constants and translations
import { questionTypeQuestion } from 'constants/questionTypes';
import { words } from '../translations';

const Slider = ({
  id,
  question,
  saveUpdatedAnswers,
  currentQuestionDet,
  language,
}) => {
  const [sliderValue, setSliderValue] = useState(5);
  const [isDragging, setIsDragging] = useState(false);
  const [clickedVal, setClickedVal] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  // Detect if the device is mobile
  useEffect(() => {
    const handleResize = () => {
      if (typeof window !== 'undefined') {
        setIsMobile(window.innerWidth <= 768); // Adjust the breakpoint as needed
      }
    };

    window.addEventListener('resize', handleResize);

    // Initial check
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (!isDragging) return;

      const track = document.getElementById('slider-track');
      const trackRect = track.getBoundingClientRect();
      let newValue = ((e.clientX - trackRect.left) / trackRect.width) * 10;

      newValue = Math.min(Math.max(newValue, 0), 10);

      setSliderValue(newValue);
      saveValue(newValue);
    };

    const handleMouseUp = () => {
      setIsDragging(false);
      setSliderValue(Math.round(sliderValue));
      saveValue(Math.round(sliderValue));
    };

    const handleTouchMove = (e) => {
      if (!isDragging) return;

      const track = document.getElementById('slider-track');
      const trackRect = track.getBoundingClientRect();
      let newValue =
        ((e.touches[0].clientX - trackRect.left) / trackRect.width) * 10;

      newValue = Math.min(Math.max(newValue, 0), 10);

      setSliderValue(newValue);
      saveValue(newValue);
    };

    const handleTouchEnd = () => {
      let newValue = Math.round(sliderValue);
      setIsDragging(false);
      setSliderValue(newValue);
      saveValue(newValue);
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
    window.addEventListener('touchmove', handleTouchMove);
    window.addEventListener('touchend', handleTouchEnd);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('touchmove', handleTouchMove);
      window.removeEventListener('touchend', handleTouchEnd);
    };
  }, [isDragging, sliderValue]);

  useEffect(() => {
    if (currentQuestionDet?.response) {
      setSliderValue(currentQuestionDet?.response?.response);
      setClickedVal(true);
    } else {
      setSliderValue(5);
      setClickedVal(false);
    }
  }, [currentQuestionDet]);

  const handleClick = (e) => {
    const track = document.getElementById('slider-track');
    const rect = track.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const newValue = Math.round((x / rect.width) * 10);
    setSliderValue(newValue);
    saveValue(newValue);
  };

  const handleMouseDown = () => {
    setIsDragging(true);
  };

  const handleTouchStart = () => {
    setIsDragging(true);
  };

  const saveValue = (newValue) => {
    setClickedVal(true);
    SaveResponse(newValue);
  };

  // Save the response
  function SaveResponse(newValue) {
    const QuesResp = {
      id: question?.dimension,
      factor: question?.factor,
      q: question?.q,
      ques_order: question?.order,
      response: newValue,
      role: question?.role,
      reverse: question?.reverse || false,
      clicked: true,
      ques_sort_order: currentQuestionDet?.id,
    };

    saveUpdatedAnswers(
      currentQuestionDet.id,
      QuesResp,
      questionTypeQuestion
    );
  }

  // Interpolate between two colors
  const lerpColor = (color1, color2, t) => {
    const r = color1.r + t * (color2.r - color1.r);
    const g = color1.g + t * (color2.g - color1.g);
    const b = color1.b + t * (color2.b - color1.b);
    return { r, g, b };
  };

  // Calculate the thumb color
  const getThumbColor = () => {
    const color1 = { r: 248, g: 168, b: 176 };
    const color2 = { r: 254, g: 205, b: 141 };
    const color3 = { r: 149, g: 230, b: 210 };
    const t = sliderValue / 10;

    if (t < 0.5232) {
      const newT = t / 0.5232;
      const interpolatedColor = lerpColor(color1, color2, newT);
      return `rgb(${interpolatedColor.r}, ${interpolatedColor.g}, ${interpolatedColor.b})`;
    } else {
      const newT = (t - 0.5232) / (1 - 0.5232);
      const interpolatedColor = lerpColor(color2, color3, newT);
      return `rgb(${interpolatedColor.r}, ${interpolatedColor.g}, ${interpolatedColor.b})`;
    }
  };

  const thumbPosition = {
    left: `calc(${(sliderValue / 10) * 100}%)`,
  };

  const dotCount = 11;
  const dots = Array.from({ length: dotCount }, (_, index) => (
    <Dot
      key={index}
      style={{ left: `${(index / (dotCount - 1)) * 100}%` }}
      id={`dot_${(index / (dotCount - 1)) * 10}_${id}`}
    />
  ));

  // Define the range labels and their corresponding positions
  const ranges = [
    {
      label: 'I would not recommend',
      start: 0,
      end: 6.45,
    },
    {
      label: 'I am undecided',
      start: 6.55,
      end: 8.45,
    },
    {
      label: 'I would recommend',
      start: 8.55,
      end: 10,
    },
  ];

  // Function to convert slider value to percentage
  const valueToPercentage = (value) => {
    return (value / 10) * 100;
  };

  return (
    <Container>
      {/* Slider Track */}
      <TrackWrapper
        onClick={handleClick}
        onMouseDown={handleMouseDown}
        onTouchStart={handleTouchStart}
      >
        <Track id={'slider-track'}>
          <DotsContainer id={`dots_${id}`}>{dots}</DotsContainer>
          <Thumb
            style={{
              ...thumbPosition,
              backgroundColor: getThumbColor(),
            }}
            onMouseDown={handleMouseDown}
            onTouchStart={handleTouchStart}
            id={`thumb_${id}`}
          >
            <Tooltip clicked={clickedVal} id={`tip_${id}`}>
              {Math.round(sliderValue)}
              <Triangle />
            </Tooltip>
          </Thumb>
        </Track>
      </TrackWrapper>

      {/* Add some whitespace */}
      <Spacing />

      {/* Range Blocks (Vertical Lines, Bottom Border, and Labels) */}
      <RangeWrapper>
        {ranges.map((range, index) => {
          const isCurrentRange =
            sliderValue >= range.start && sliderValue <= range.end;

          // On mobile, show only the text of the current range
          const showLabel = !(isMobile && !isCurrentRange);

          return (
            <RangeBlock
              key={index}
              style={{
                left: `${valueToPercentage(range.start)}%`,
                width: `${
                  valueToPercentage(range.end) - valueToPercentage(range.start)
                }%`,
              }}
            >
              {/* Vertical lines at range start and end */}
              <RangeSeparatorLeft />
              <RangeSeparatorRight />

              {/* Bottom border to square off the range */}
              <RangeBottomBorder />

              {/* Label */}
              {showLabel && (
                <RangeLabelText isCurrentRange={isCurrentRange}>
                  <LabelText isCurrentRange={isCurrentRange}>
                    {range.label}
                  </LabelText>
                </RangeLabelText>
              )}
            </RangeBlock>
          );
        })}
      </RangeWrapper>
    </Container>
  );
};

export default Slider;

// Styled Components
const Container = styled.div`
  width: 100%;
  padding-top: 70px;
  user-select: none;
`;

const TrackWrapper = styled.div`
  position: relative;
  height: calc(10px + 10px);
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const Track = styled.div`
  width: 100%;
  height: 6px;
  background: linear-gradient(
    to right,
    #f8a8b0 0%,
    #fecd8d 52.32%,
    #95e6d2 100%
  );
  position: relative;
  border-radius: 5px;
  cursor: pointer;
`;

const Thumb = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #000;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  user-select: none;
  border: 4px solid white;
  box-shadow: 0px 8px 20px rgba(63, 134, 254, 0.08);
`;

const Tooltip = styled.div`
  position: absolute;
  top: -35px;
  left: 50%;
  transform: translateX(-50%);
  background: #666d79;
  border-radius: 8px;
  width: 33px;
  height: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => (props.clicked ? '#FFFFFF' : '#D6DBDF')};
  font-size: 14px;
  font-weight: ${(props) => (props.clicked ? '700' : '400')};
`;

const Triangle = styled.div`
  position: absolute;
  bottom: -4px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4px 4px 0;
  border-color: #666d79 transparent transparent;
`;

const DotsContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 1px;
  display: flex;
  justify-content: space-between;
`;

const Dot = styled.div`
  width: 4px;
  height: 4px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
`;

const Spacing = styled.div`
  height: 20px;
`;

const RangeWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 40px;
  font-family: 'Raleway', sans-serif;
`;

const RangeBlock = styled.div`
  position: absolute;
  bottom: 0;
  height: 100%;
`;

const RangeSeparatorLeft = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 2px;
  height: 15px;
  background-color: #ccc;
`;

const RangeSeparatorRight = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 2px;
  height: 15px;
  background-color: #ccc;
`;

const RangeBottomBorder = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.5px;
  background-color: #ccc;
`;

// Updated RangeLabelText to adjust styles based on the current range
const RangeLabelText = styled.div`
  position: absolute;
  bottom: 16px;
  left: 50%;
  transform: translateX(-50%);
  font-size: ${(props) => (props.isCurrentRange ? '12px' : '10px')};
  font-weight: ${(props) => (props.isCurrentRange ? '700' : '600')};
  color: ${(props) => (props.isCurrentRange ? '#000' : '#ccc')};
`;

// Updated LabelText to adjust styles based on the current range
const LabelText = styled.span`
  white-space: nowrap;
`;