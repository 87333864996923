export const words = {
    "intro1-0": {
        "EN": "Your",
        "FR": "Ton",
        "ES": "Tu",
        "TUR": "Sizin"
    },
    "intro1-1": {
        "EN": "experience as an",
        "FR": "expérience en tant que",
        "ES": "experiencia, como",
        "TUR": "bu kültürün"
    },
    "intro1-2": {
        "EN": "integral part",
        "FR": "partie intégrante",
        "ES": "parte integral",
        "TUR": "ayrılmaz bir parçası olarak"
    },
    "intro1-3": {
        "EN": "of this culture",
        "FR": "de cette culture",
        "ES": "de esta cultura",
        "TUR": ""
    },
    "intro1-4": {
        "EN": "matters",
        "FR": "compte",
        "ES": "nos importa",
        "TUR": "deneyiminiz önemlidir."
    },
    "intro2-0": {
        "EN": "Your",
        "FR": "T'es",
        "ES": "Tu",
        "TUR": "Sizin"
    },
    "intro2-1": {
        "EN": "responses",
        "FR": "réponses",
        "ES": "respuestas",
        "TUR": "cevaplarınız"
    },
    "intro2-2": {
        "EN": "from this survey",
        "FR": "de cette enquête",
        "ES": "en esta encuesta",
        "TUR": "bu anketten"
    },
    "intro2-3": {
        "EN": "will be used to create",
        "FR": "seront utilisées pour créer",
        "ES": "se utilizarán para crear",
        "TUR": "oluşturmak için kullanılacaktır"
    },
    "intro2-4": {
        "EN": "real change.",
        "FR": "un vrai changement.",
        "ES": "un cambio real.",
        "TUR": "gerçek bir değişim."
    },
    "intro3-0": {
        "EN": "Your",
        "FR": "T'es",
        "ES": "Tu",
        "TUR": "Sizin"
    },
    "intro3-1": {
        "EN": "responses",
        "FR": "réponses",
        "ES": "respuestas",
        "TUR": "cevaplarınız"
    },
    "intro3-2": {
        "EN": "are anonymous",
        "FR": "sont anonymes",
        "ES": "son anónimas",
        "TUR": "anonimdir"
    },
    "intro3-3": {
        "EN": " and will never be ",
        "FR": " et ne seront jamais ",
        "ES": " y nunca serán ",
        "TUR": "ve asla"
    },
    "intro3-4": {
        "EN": "attached to",
        "FR": "attachées à",
        "ES": "vinculadas a",
        "TUR": "kimliğinize"
    },
    "intro3-5": {
        "EN": "your identity.",
        "FR": "votre identité.",
        "ES": "tu identidad.",
        "TUR": "bağlanmayacaktır."
    },
    "intro4-0": {
        "EN": "Do you agree with your personal data being processed as described below and in our privacy statement?",
        "FR": "Êtes-vous d'accord pour que vos données personnelles soient traitées comme décrit ci-dessus et dans notre déclaration de confidentialité ?",
        "ES": "¿Estás de acuerdo con que tus datos personales sean procesados como se describe abajo y en nuestra declaración de privacidad?",
        "TUR": "Aşağıda ve gizlilik bildirimimizde açıklandığı şekilde kişisel verilerinizin işlenmesini kabul ediyor musunuz?"
    },
    "intro4-1": {
        "EN": "Privacy Agreement",
        "FR": "Accord de confidentialité",
        "ES": "Acuerdo de Privacidad",
        "TUR": "Gizlilik Sözleşmesi"
    },
    "intro5-0": {
        "EN": "Are you over the age of 16?",
        "FR": "Avez-vous plus de 16 ans ?",
        "ES": "Acuerdo de Privacidad",
        "TUR": "16 yaşından büyük müsünüz?"
    },
    "intro7-0": {
        "EN":"Are you 19 years old or older?",
        "FR":"Avez-vous 19 ans ou plus?",
        "ES":"¿Tienes 19 años o más?",
        "TUR":"19 yaşında veya daha büyük müsünüz?"
    },
    "back": {
        "EN": "Back",
        "FR": "Retour",
        "ES": "Atrás",
        "TUR": "Geri"
    },
    "Start": {
        "EN": "Start",
        "FR": "Démarrer",
        "ES": "Empezar",
        "TUR": "Başla"
    },
    "Next": {
        "EN": "Next",
        "FR": "Suivant",
        "ES": "Siguiente",
        "TUR": "Sonraki"
    },
    "I agree": {
        "EN": "I agree",
        "FR": "Je suis d'accord",
        "ES": "Estoy de acuerdo",
        "TUR": "Kabul ediyorum"
    },
    "Submit": {
        "EN": "Submit",
        "FR": "Soumettre",
        "ES": "Enviar",
        "TUR": "Gönder"
    },
    "of": {
        "EN": "of",
        "FR": "de",
        "ES": "de",
        "TUR": "nın"
    },
    "category": {
        "EN": "Categorical Question",
        "FR": "Question catégorique",
        "ES": "Pregunta Categórica",
        "TUR": "Kategorik Soru"
    },
    "Culture Question": {
        "EN": "Culture Question",
        "FR": "Question Culturelle",
        "ES": "Pregunta Cultural",
        "TUR": "Kültür Sorusu"
    },
    "qualifier": {
        "EN": "In this culture...",
        "FR": "Dans cette culture...",
        "ES": "En esta cultura...",
        "TUR": "Bu kültürde..."
    },
    "Strongly Disagree": {
        "EN": "Strongly Disagree",
        "FR": "Fortement en désaccord",
        "ES": "Muy en desacuerdo",
        "TUR": "Kesinlikle Katılmıyorum"
    },
    "Strongly Agree": {
        "EN": "Strongly Agree",
        "FR": "Fortement d'accord",
        "ES": "Totalmente de acuerdo",
        "TUR": "Kesinlikle Katılıyorum"
    },
    "Neutral": {
        "EN": "Neutral",
        "FR": "Neutre",
        "ES": "Neutral",
        "TUR": "Tarafsız"
    },
    "Agree": {
        "EN": "Agree",
        "FR": "D'accord",
        "ES": "De acuerdo",
        "TUR": "Katılıyorum"
    },
    "Disagree": {
        "EN": "Disagree",
        "FR": "Pas d'accord",
        "ES": "En desacuerdo",
        "TUR": "Katılmıyorum"
    },
    "min_feedback": {
        "EN": "We noticed you scored the following statement amongst lowest overall",
        "FR": "Nous avons remarqué que vous avez obtenu le score le plus bas pour l'énoncé suivant",
        "ES": "Hemos notado que calificaste la siguiente afirmación entre las más bajas en general",
        "TUR": "Aşağıdaki ifadeye genel olarak en düşük puanı verdiğinizi fark ettik"
    },
    "max_feedback": {
        "EN": "We noticed you scored the following statement amongst highest overall",
        "FR": "Nous avons remarqué que vous avez obtenu le score le plus élevé pour l'énoncé suivant",
        "ES": "Hemos notado que calificaste la siguiente afirmación entre las más altas en general",
        "TUR": "Aşağıdaki ifadeye genel olarak en yüksek puanı verdiğinizi fark ettik"
    },
    "min_feedback1": {
        "EN": "You scored a",
        "FR": "Vous avez obtenu un",
        "ES": "La has calificado como",
        "TUR": "Şu puanı verdiniz"
    },
    "min_feedback2": {
        "EN": "Can you provide specific examples of how this factor has impacted you or your colleagues?",
        "FR": "Pouvez-vous fournir des exemples spécifiques de la façon dont ce facteur a impacté vous ou vos collègues?",
        "ES": "¿Puedes proporcionar ejemplos específicos de cómo este factor te ha afectado a ti o a tus colegas?",
        "TUR": "Bu faktörün sizi veya meslektaşlarınızı nasıl etkilediğine dair belirli örnekler verebilir misiniz?"
    },
    "min_feedback3": {
        "EN": "Please provide an example of why.",
        "FR": "Veuillez fournir un exemple pour expliquer pourquoi.",
        "ES": "Por favor proporciona un ejemplo de por qué.",
        "TUR": "Lütfen bir örnek verin; nedenini açıklayın."
    },
    "max_feedback1": {
        "EN": "We'd love to know more! Please provide an example of why.",
        "FR": "Nous aimerions en savoir plus! Veuillez fournir un exemple pour expliquer pourquoi.",
        "ES": "¡Nos encantaría saber más! Por favor proporciona un ejemplo de por qué.",
        "TUR": "Daha fazlasını duymak isteriz! Lütfen neden böyle düşündüğünüze dair bir örnek verin."
    },
    "textarea": {
        "EN": "Type your answer here",
        "FR": "Tapez votre réponse ici",
        "ES": "Escribe tu respuesta aquí",
        "TUR": "Cevabınızı buraya yazın"
    },
    "Skip": {
        "EN": "Skip",
        "FR": "Passer",
        "ES": "Omitir",
        "TUR": "Atla"
    },
    "welcomeMessage": {
        "EN": "We understand that your time is precious, but we'd like to take a moment to emphasize how important your feedback is to us. Your thoughts and insights can make a world of difference!",
        "FR": "Nous comprenons que votre temps est précieux, mais nous aimerions prendre un moment pour souligner à quel point vos retours sont importants pour nous. Vos pensées et insights peuvent faire toute la différence !",
        "ES": "Entendemos que tu tiempo es precioso, pero nos gustaría tomar un momento para enfatizar cuán importante es tu feedback para nosotros. ¡Tus pensamientos y percepciones pueden marcar una gran diferencia!",
        "TUR": "Zamanınızın değerli olduğunu biliyoruz, ancak geri bildiriminizin bizim için ne kadar önemli olduğunu vurgulamak istiyoruz. Düşünceleriniz ve içgörüleriniz büyük bir fark yaratabilir!"
    },
    "driveChangeTitle": {
        "EN": "Drive meaningful change",
        "FR": "Provoquer un changement significatif",
        "ES": "Impulsar un cambio significativo",
        "TUR": "Anlamlı değişimi yönlendirin"
    },
    "driveChangeText": {
        "EN": "Your feedback pinpoints areas for improvement, enabling us to prioritize and implement impactful changes.",
        "FR": "Vos retours identifient les domaines à améliorer, nous permettant de prioriser et mettre en œuvre des changements significatifs.",
        "ES": "Tu feedback identifica áreas para mejorar, permitiéndonos priorizar e implementar cambios significativos.",
        "TUR": "Geri bildiriminiz, iyileştirme alanlarını belirlememize yardımcı olarak önceliklendirme yapmamızı ve etkili değişiklikleri uygulamamızı sağlar."
    },
    "reinforceStrengthsTitle": {
        "EN": "Reinforce our strengths",
        "FR": "Renforcer nos points forts",
        "ES": "Reforzar nuestras fortalezas",
        "TUR": "Güçlü yanlarımızı pekiştirin"
    },
    "reinforceStrengthsText": {
        "EN": "Your positive feedback helps us maintain and enhance the features that resonate with you, ensuring exceptional services.",
        "FR": "Vos retours positifs nous aident à maintenir et améliorer les fonctionnalités qui vous parlent, assurant des services exceptionnels.",
        "ES": "Tu feedback positivo nos ayuda a mantener y mejorar las características que resuenan contigo, asegurando servicios excepcionales.",
        "TUR": "Olumlu geri bildiriminiz, sizin için önemli olan özellikleri korumamıza ve geliştirmemize yardımcı olur, böylece üstün hizmet sunabiliriz."
    },
    "influenceDecisionsTitle": {
        "EN": "Influence strategic decisions",
        "FR": "Influencer les décisions stratégiques",
        "ES": "Influir en las decisiones estratégicas",
        "TUR": "Stratejik kararlara etki edin"
    },
    "influenceDecisionsText": {
        "EN": "Your input informs our decision-making process, leading to a more responsive and user-focused organization.",
        "FR": "Vos contributions éclairent notre processus décisionnel, menant à une organisation plus réactive et centrée sur l'utilisateur.",
        "ES": "Tu aporte informa nuestro proceso de toma de decisiones, llevando a una organización más receptiva y centrada en el usuario.",
        "TUR": "Katkılarınız, karar verme sürecimize ışık tutarak daha duyarlı ve kullanıcı odaklı bir organizasyon olmamızı sağlar."
    },
    "Leave feedback": {
        "EN": "Leave feedback",
        "FR": "Laisser un commentaire",
        "ES": "Dejar feedback",
        "TUR": "Geri bildirim bırakın"
    },
    "Skip anyway": {
        "EN": "Skip anyway",
        "FR": "Passer quand même",
        "ES": "Omitir de todos modos",
        "TUR": "Yine de atla"
    },
    "sharing": {
        "EN": "By sharing your experiences, you help us:",
        "FR": "En partageant vos expériences, vous nous aidez à :",
        "ES": "Al compartir tus experiencias, nos ayudas a:",
        "TUR": "Deneyimlerinizi paylaşarak bize şu konularda yardımcı oluyorsunuz:"
    },
    "matters": {
        "EN": "Your Feedback Matters!",
        "FR": "Vos retours sont importants !",
        "ES": "¡Tu Feedback Importa!",
        "TUR": "Geri Bildiriminiz Önemlidir!"
    },
    "Very Poor": {
        "EN": "Very Poor",
        "FR": "Très mauvais",
        "ES": "Muy Malo",
        "TUR": "Çok Zayıf"
    },
    "Excellent": {
        "EN": "Excellent",
        "FR": "Excellent",
        "ES": "Excelente",
        "TUR": "Mükemmel"
    },
    "Congratulations": {
        "EN": "You're almost done!",
        "FR": "Vous avez presque terminé !",
        "ES": "¡Casi has terminado!",
        "TUR": "Neredeyse bitirdiniz!"
    },
    "Value": {
        "EN": "Your valuable insights matter to us. Go ahead and hit that",
        "FR": "Vos précieux retours nous importent. Allez-y et appuyez sur",
        "ES": "Tu percepcion es valiosa e importante para nosotros. Presiona el botón",
        "TUR": "Değerli düşünceleriniz bizim için önemli. Lütfen"
    },
    "Value2": {
        "EN": "button to share your thoughts and help shape our organization’s future.",
        "FR": "bouton pour partager vos pensées et aider à façonner l'avenir de notre organisation.",
        "ES": "para compartir tus pensamientos y ayudar a construir el futuro de nuestra organización.",
        "TUR": "düğmesine basarak düşüncelerinizi paylaşın ve organizasyonumuzun geleceğini şekillendirmemize yardımcı olun."
    },
    "Feedback": {
        "EN": "Feedback",
        "FR": "Commentaire",
        "ES": "Feedback",
        "TUR": "Geri Bildirim"
    },
    "category_completed": {
        "EN": "Thank you for answering these preliminary questions.",
        "FR": "Merci d'avoir répondu à ces questions préliminaires.",
        "ES": "Gracias por responder a estas preguntas preliminares.",
        "TUR": "Bu ön soruları yanıtladığınız için teşekkür ederiz."
    },
    "category_completed2": {
        "EN": "Now we are going to ask you some questions about your experience with {organizationName}. Your perspectives are important.",
        "FR": "Maintenant, nous allons vous poser quelques questions sur votre expérience avec {organizationName}. Vos perspectives sont importantes.",
        "ES": "Ahora vamos a hacerte algunas preguntas sobre tu experiencia con {organizationName}. Tus perspectivas son importantes.",
        "TUR": "Şimdi {organizationName} ile deneyiminiz hakkında birkaç soru soracağız. Fikirleriniz bizim için önemli."
    },
    "Searching": {
        "EN": "Looking for an existing response",
        "FR": "Recherche d'une réponse existante",
        "ES": "Buscando una respuesta existente",
        "TUR": "Mevcut bir yanıt aranıyor"
    },
    "Searching2": {
        "EN": "It looks like we've found an existing survey response. Would you like to:",
        "FR": "Il semble que nous ayons trouvé une réponse à l'enquête existante. Souhaitez-vous :",
        "ES": "Parece que hemos encontrado una respuesta existente a la encuesta. ¿Te gustaría:",
        "TUR": "Görünüşe göre mevcut bir anket yanıtı bulduk. Şunu yapmak ister misiniz?"
    },
    "Restart": {
        "EN": "Restart the survey",
        "FR": "Redémarrer l'enquête",
        "ES": "Reiniciar la encuesta",
        "TUR": "Anketi yeniden başlat"
    },
    "Load": {
        "EN": "Load the existing response",
        "FR": "Charger la réponse existante",
        "ES": "Cargar la respuesta existente",
        "TUR": "Mevcut yanıtı yükle"
    },
    "completed_on": {
        "EN": "This survey was completed on",
        "FR": "Cette enquête a été complétée le",
        "ES": "Esta encuesta fue completada el",
        "TUR": "Bu anket şu tarihte tamamlandı"
    },
    "survey_status1": {
        "EN": "We are unable to get information about this survey.",
        "FR": "Nous ne pouvons pas obtenir d'informations sur cette enquête.",
        "ES": "No podemos obtener información sobre esta encuesta.",
        "TUR": "Bu anket hakkında bilgi alamıyoruz."
    },
    "team_qualifier": {
        "EN": "In {team}'s culture...",
        "FR": "Dans la culture de {team}...",
        "ES": "En la cultura de {team}...",
        "TUR": "{team} kültüründe..."
    },
    "In the hockey environment (training and competition)...": {
        "EN": "In the hockey environment (training and competition)...",
        "FR": "Dans l'environnement du hockey (entraînement et compétition)...",
        "ES": "En el entorno del hockey (entrenamiento y competencia)...",
        "TUR": "Buz hokeyi ortamında (antrenman ve müsabaka)..."
    },
    "intro4-2": {
        "EN": "DISCLAIMER: This survey uses conversational AI at times to ask more specifically about your experiences. If you are uncomfortable with any AI prompt and do not want to respond, please just hit the skip button. Thank you.",
        "FR": "AVERTISSEMENT : Cette enquête utilise l'IA conversationnelle parfois pour demander plus spécifiquement sur vos expériences. Si vous n'êtes pas à l'aise avec une invite d'IA et ne souhaitez pas répondre, appuyez simplement sur le bouton Ignorer. Merci.",
        "TUR": "AÇIKLAMA: Bu anket, deneyimlerinizi daha spesifik bir şekilde sormak için zaman zaman sohbet temelli yapay zekâ kullanır. Herhangi bir yapay zekâ metniyle kendinizi rahatsız hissediyorsanız ve yanıt vermek istemiyorsanız, lütfen atla düğmesine basın. Teşekkürler."
    },
    "Never": {
        "EN": "Never",
        "FR": "Jamais",
        "ES": "Nunca",
        "TUR": "Asla"
      },
      "Rarely": {
        "EN": "Rarely",
        "FR": "Rarement",
        "ES": "Rara vez",
        "TUR": "Nadiren"
      },
      "Sometimes": {
        "EN": "Sometimes",
        "FR": "Parfois",
        "ES": "A veces",
        "TUR": "Bazen"
      },
      "Often": {
        "EN": "Often",
        "FR": "Souvent",
        "ES": "A menudo",
        "TUR": "Sıkça"
      },
      "Always": {
        "EN": "Always",
        "FR": "Toujours",
        "ES": "Siempre",
        "TUR": "Her zaman"
      },
      "I would not recommend":{
        "EN":"I would not recommend",
        "FR":"Je ne recommanderais pas",
        "ES":"No recomendaría",
        "TUR":"Tavsiye etmem"
      },
      "I would recommend":{
        "EN":"I would recommend",
        "FR":"Je recommanderais",
        "ES":"Recomendaría",
        "TUR":"Tavsiye ederim"
      },
      "Type your answer here":{
        "EN":"Type your answer here",
        "FR":"Tapez votre réponse ici",
        "ES":"Escribe tu respuesta aquí",
        "TUR":"Cevabınızı buraya yazın"
      },
      "Thank you for completing the":{
        "EN":"Thank you for completing the",
        "FR":"Merci d'avoir complété le",
        "ES":"Gracias por completar el",
        "TUR":"Tamamladığınız için teşekkür ederiz"
      }
}
