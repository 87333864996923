import React, { useState} from 'react';
import styled from 'styled-components';
import Pic from 'assets/images/gb.png'
import FR from 'assets/images/fr.png'
import ES from 'assets/images/ES.png'
import TUR from 'assets/images/TUR.svg'
import { useNavigate  } from 'react-router-dom';

const Language = ({language,setLanguage,languages}) =>{
    // const [language,setLanguage] = useState('EN')
    const navigate = useNavigate();


    const [open,setOpen] = useState(false)

    const getPic = () =>{
        if(language === 'EN'){
            return Pic
        }else if(language === 'FR'){
            return FR
        }else if(language === 'ES'){
            return ES
        }
        else if(language === 'TUR'){
            return TUR
        }
    }

    const SelectLanguage = (lang) =>{
        setOpen(!open)
        setLanguage(lang)

        if(lang === 'FR'){
            navigate(`?language=1`, { replace: true });
        }
        if(lang === 'EN'){
            navigate(`?language=0`, { replace: true });
        }
        if(lang === 'ES'){
            navigate(`?language=2`, { replace: true });
        }
        if(lang === 'TUR'){
            navigate(`?language=3`, { replace: true });
        }
    }

    return (
        <Container onClick={()=>setOpen(!open)}>
            <InnerContainer>
                <Image src={getPic()} alt="Image description" />
                <Text>{language}</Text>

                <Options open={open}>
                    <Option onClick={()=>SelectLanguage('EN')}>English</Option>
                    {languages?.includes('FR') && <Option onClick={()=>SelectLanguage('FR')}>Français</Option>}
                    {languages?.includes('TUR') && <Option onClick={()=>SelectLanguage('TUR')}>Türkçe</Option>}
                    {languages?.includes('ES') && <Option onClick={()=>SelectLanguage('ES')}>Español</Option>}
                </Options>
            </InnerContainer>
           
        </Container>
    )
}


export default Language


const Container = styled.div`
    position:absolute;
    top:15px;
    right:30px;
    cursor:pointer;
    z-index:10000;
`

const InnerContainer = styled.div`
    position:relative;
    display:flex;
    align-items:center;
`

const Image = styled.img`
    width:24px;
    margin-right:8px;
`

const Text = styled.div`
font-weight: 600;
font-size: 16px;
color: #2A3039;
`
const Options = styled.div`
    display:${props=>props.open?'flex':'none'};
    flex-direction:column;
    background:white;
    border-radius:8px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    position:absolute;
    top:40px;
    right:0px;


`
const Option = styled.div`
    padding:8px 16px;
    font-size:16px;
    color:#2A3039;
    cursor:pointer;
    &:hover{
        background:#EBF1FD;
    }
`