import React from 'react';
import styled from 'styled-components';
import Slider from '../Components/Slider';
import LikertScale from '../Components/Likert';
import RangeSlider from '../Components/RangeSlider';
import {words} from '../translations'

const Question = ({ question, saveUpdatedAnswers, currentQuestionDet, language,qualifier,team_qualifier,teamName }) => {

  const get_qualifier = () =>{
    if(team_qualifier){
      return words['team_qualifier'][language].replace('{team}',teamName)
    }

    if(question?.qualifier){
      return question?.qualifier
    }

    if(qualifier){
      if(language== 'FR' && qualifier == 'In the hockey environment (training and competition)...'){

        return 'Dans l’environnement du hockey (entraînement et compétition)...'
      } 

      if(language == 'FR' && qualifier == "In our hockey environment..."){
        return "Dans notre environnement de hockey..."
      }
      return qualifier
    }



    return words["qualifier"][language]
   
  }

  const getQuestion = () =>{
    if(language== 'FR' && question?.fr){
      return question?.fr
    }
    if(language == 'ES' && question?.es){
      return question?.es
    }
    if(language == 'TUR' && question?.tr){
      return question?.tr
    }

    return question?.question
  }

  const getScaleType = () =>{
    if(question?.scaleType == "likert"){
      return  <LikertScale 
      id={`slider-track-${currentQuestionDet?.id}`} 
      question={question} 
      language={language}
      saveUpdatedAnswers={saveUpdatedAnswers} 
      currentQuestionDet={currentQuestionDet} />
    }
    return  <Slider 
    id={`slider-track-${currentQuestionDet?.id}`} 
    question={question} 
    language={language}
    saveUpdatedAnswers={saveUpdatedAnswers} 
    currentQuestionDet={currentQuestionDet} />
  }

 return (<QuestionContainer>
    <Title>{question?.title? question.title : words["Culture Question"][language]}</Title>
    {/* <Qualifier bold={!question?.qualifier}>{get_qualifier()}</Qualifier> */}
    <QuestionText bold={question?.qualifier}>{getQuestion()}</QuestionText>
    {getScaleType()}
  </QuestionContainer>)
};
export default Question;

const QuestionContainer = styled.div`
  width: 100%;
  max-width:600px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Title = styled.div`
  color: #2D70E2;
  text-transform: uppercase;
  font-size:14px;
  font-weight:600;
  margin-bottom: 40px;
`

const Qualifier = styled.div`
  color:#2A3039;
  font-size:${props=>props.bold?'20px':'16px'};
  font-weight:${props=>props.bold?600:400};
  margin-bottom:14px;
  line-height:1.4;
`

const QuestionText = styled.p`
  font-size: ${props=>props.bold?'20px':'16px'};
  font-weight:${props=>props.bold?600:400};
  color: #2A3039;
  white-space: pre-wrap;
`;

